import React from "react";
import { Route, Routes } from "react-router-dom";
import { seoData } from './config/seo';
import SEO from './components/SEO';
import Sidebar from "./components/Sidebar";
import Home from "./pages/Home";
import About from "./pages/About";
import Projects from "./pages/Projects";
import "./App.css";
import CaseStudy from "./pages/CaseStudy";
import ScrollToTop from "./ScrollToTop";
import WritingsPage from "./pages/Writings";
import Footer from "./components/Footer";
import ArticleDetail from "./components/ArticleDetail";
import MySpaceProfile from "./pages/MySpaceProfile";
import NotFound from "./pages/404";
import Now from "./pages/Now";

function App() {
  return (
    <div className="app-container">
      <Sidebar />
      <main className="content-container" role="main" tabIndex="-1">
        <Routes>
          <Route path="/" element={
            <>
              <SEO {...seoData.home} />
              <Home />
            </>
          } />
          <Route path="/about" element={
            <>
              <SEO {...seoData.about} />
              <About />
            </>
          } />
          <Route path="/projects" element={<Projects />} />
          <Route path="/writing" element={
            <>
              <SEO {...seoData.writing} />
              <WritingsPage />
            </>
          } />
          <Route path="/now" element={<Now />} />
          <Route path="/case-study/:slug" element={<CaseStudy />} />
          <Route path="/articles/:slug" element={
            <React.Suspense fallback={<div>Loading...</div>}>
              <ArticleDetail seoData={seoData.articles} />
            </React.Suspense>
          } />
          <Route path="/myspace" element={<MySpaceProfile />} />
          <Route path="*" element={<NotFound />} />
        </Routes>
        <Footer />
      </main>
      <ScrollToTop />
    </div>
  );
}

export default App;
